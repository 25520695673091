<template>
    <div>
        <hr>
        <div class="row">
            <div class="col-1"/>
            <div class="col-10">
                <a href="impressum" class="d-flex justify-content-end nav-link">
                    Impressum
                </a>
            </div>
            <div class="col-1"/>
        </div>
        <br>
    </div>
</template>