<template>
    <div class="row bg-primary">
        <div class="col-md-1"/>
        <div class="col-md-10">
            <nav class="navbar navbar-expand-sm navbar-light bg-faded">
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#nav-content"
                    aria-controls="nav-content"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <a class="navbar-brand mb-0 h1" href="/">Ferienhaus Reinharz</a>

                <div
                    class="collapse navbar-collapse justify-content-end"
                    id="nav-content">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item">
                            <a class="nav-link text-dark btn" href="/"><b>Startseite</b></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-dark btn" href="/pictures"><b>Bilder</b></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-dark btn" href="/location"><b>Umgebung</b></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-dark btn" href="/prices"><b>Preise</b></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-dark btn" href="/contact"><b>Kontakt</b></a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <div class="col-md-1"/>
    </div>
</template>