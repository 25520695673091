<template>
    <div class="row">
      <div class="col-md-2"/>
      <div class="col-md-8">
        <br>
        <h2>Haftung:</h2>
        <p> <b>Haftung für Inhalte</b> <br>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            <br><br>
            <b>Haftung für Links</b> <br>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
            
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            <br><br>
            <b>Urheberrecht</b><br>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
            
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
        <h2>Datenschutz:</h2>
        <p>Datenschutzerklärung
            Verantwortlich im Sinne der Datenschutzgesetzes:
            
            <br><br>Markus u. Michaela Pfülb
            <br>Ringstraße 20
            <br>97490 Poppenhausen
            
            <br><br>Datenschutz
            
            <br>Als Webseitenbetreiber nehmen wir den Schutz aller persönlichen Daten sehr ernst. Alle personenbezogenen Informationen werden vertraulich und gemäß den gesetzlichen Vorschriften behandelt, wie in dieser Datenschutzerklärung erläutert.
            
            <br>Unserer Webseite kann selbstverständlich genutzt werden, ohne dass Sie persönliche Daten angeben. Wenn jedoch zu irgendeinem Zeitpunkt persönliche Daten wie z.B. Name, Adresse oder E-Mail abgefragt werden, wird dies auf freiwilliger Basis geschehen. Niemals werden von uns erhobene Daten ohne Ihre spezielle Genehmigung an Dritte weitergegeben.
            
            <br>Datenübertragung im Internet, wie zum Beispiel über E-Mail, kann immer Sicherheitslücken aufweisen. Der komplette Schutz der Daten ist im Internet nicht möglich.
            
            <br><br>Datenschutzerklärung Google Analytics
            
            <br>Unsere Website nutzt den Analysedienst Google Analytics, betrieben von Google Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Google Analytics nutzt “Cookies”, das sind kleine Textdateien, die in Ihrem Browser gespeichert werden und die es ermöglichen, die Nutzung unserer Website durch die Besucher zu analysieren. Von den Cookies werden Daten über Ihre Nutzung unserer Webseite gesammelt, die normalerweise an einen Google-Server in den USA übertragen und gespeichert werden.
            
            <br>Wenn die IP-Anonymisierung auf unserer Webseite aktiviert wurde, wird Ihre IP-Adresse von Google innerhalb der Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vorab gekürzt. In seltenen Ausnahmefällen kann die komplette IP-Adresse an einen Google Server in den USA übertragen werden, dann wird diese dort gekürzt. Google nutzt diese Daten in unserem Auftrag, um die Nutzung unserer Website auszuwerten, um Berichte über die Webseitenaktivitäten zu erstellen sowie um weitere Dienstleistungen anzubieten, die mit der Webseitennutzung und Internetnutzung zusammenhängen. Die von Google Analytics erfassten IP-Adressen werden nicht mit anderen Daten von Google korreliert.
            
            <br> Die Speicherung von Cookies kann durch eine spezielle Einstellung in Ihrem Browser verweigert werden. In diesem Fall ist jedoch die Funktionalität unserer Webseite im vollen Umfang nicht gewährleistet. Zusätzlich steht ihnen ein Browser-Plugin zu Verfügung, mit dem Sie die Sammlung der auf Ihre Nutzung der Website bezogenen erzeugten Daten und IP-Adressen durch Google verhindern können. Mehr Informationen dazu finden Sie hier: http://tools.google.com/dlpage/gaoptout?hl=de
            
            <br><br>Datenschutzerklärung Google +1
            
            <br>Auf unsere Seiten werden Funktionen von Google +1 genutzt, angeboten von Google Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.
            
            <br>Sammlung und Übertragung von Daten: Sie können die Google +1-Schaltfläche nutzen, um Informationen weltweit zu veröffentlichen. Die Google +1-Schaltfläche präsentiert Ihnen und anderen Nutzern individuell abgestimmte Inhalte von Google und deren Partnern. Google sammelt Daten über die Informationen, die Sie für einen +1 Inhalt gegeben haben, sowie Daten über die Webseite, die Sie angesehen haben, während Sie auf +1 geklickt haben. Ihre +1 Daten können zusammen mit Ihrem Profilnamen und Ihrem Foto in unterschiedlichen Google-Diensten, wie Suchergebnissen, Ihrem Google-Profil, sowie auf Webseiten und Werbeanzeigen im Internet eingeblendet werden. Informationen über Ihre +1-Aktivitäten werden von Google aufgezeichnet, um die von Ihnen genutzten Google-Dienste zu verbessern. Um Google +1 Schaltflächen nutzen zu können, müssen Sie über ein öffentliches Google-Profil verfügen, in dem mindestens der Name des Profils enthalten sein muss. Alle Google-Dienste verwenden diesen Profilnamen. Manchmal kann dieser Name auch einen anderen Namen ersetzen, den Sie verwendet haben, wenn Inhalte über Ihr Google-Konto mit anderen Nutzern geteilt wurden. Nutzern, die Ihre E-Mail-Adresse kennen oder über andere identifizierende Daten von Ihnen verfügen, kann die Identität Ihres Google-Profils angezeigt werden.
            
            <br>Nutzung der gesammelten Daten: Zusätzlich zu der bereits beschriebenen Nutzung unterliegen die von Ihnen bereitgestellten Daten den geltenden Google-Datenschutzbestimmungen. Google kann allgemeine Statistiken über die +1-Aktivitäten der Nutzer veröffentlichen, oder diese an Nutzer und Partner, wie Publisher, Inserenten oder Partner- Webseiten, weitergeben.
            
            <br>Auskunft, Löschung, Sperrung
            
            <br>Zu jedem Zeitpunkt können Sie sich über die personenbezogenen Daten, deren Herkunft und Empfänger und den Nutzen der Datenverarbeitung informieren und unentgeltlich eine Korrektur, Sperrung oder Löschung dieser Daten verlangen. Bitte nutzen Sie dafür die im Impressum angegebenen Kontaktwege. Für weitere Fragen zum Thema stehen wir Ihnen ebenfalls jederzeit zur Verfügung.
            
            <br><br>Server-Log-Files
            
            <br>Der Seiten-Provider erhebt und speichert automatisch Daten in Server-Log Files, die von Ihrem Browser an uns übermittelt werden. Diese Daten enthalten:
            
            <br>– Browsertyp/ Browserversion
            
            <br>– Betriebssystem des Rechners
            
            <br>– Referrer URL
            
            <br>– Hostname des zugreifenden Rechners
            
            <br>– Uhrzeit der Serveranfrage
            
            <br>Diese Daten sind nicht personenbezogen. Es erfolgt keine Zusammenführung dieser Daten mit anderen Datenquellen. Wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden behalten wir uns das Recht vor, diese Daten nachträglich zu überprüfen.
            
            <br><br>Cookies
            
            <br>Viele Internetseiten verwenden Cookies. Cookies sind unschädlich für Ihren Rechner und virenfrei. Sie dienen dazu, Internet-Angebote für die Besucher einer Webseite freundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Computer abgelegt werden und die Ihr Browser verwendet.
            
            <br>Wir verwenden in der Regel so genannte „Session-Cookies“. Diese werden nach Verlassen unserer Seite automatisch gelöscht. Andere Cookies bleiben auf Ihrem Computer gespeichert, bis Sie diese löschen. Diese Cookies helfen dabei, Ihren Rechner beim nächsten Besuch wiederzuerkennen.
            
            <br>Über die Browsereinstellungen können sie festlegen, dass Sie über neue Cookies informiert werden und Cookies jeweils annehmen müssen. Ebenso können Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschließen oder das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Werden Cookies desaktiviert, kann die Funktionalität unserer Website eingeschränkt sein.
            
            <br><br>Kontaktformular
            
            <br>Kontaktdaten, die uns über unser Kontaktformular erreichen, werden inklusive des Inhalts der Anfrage für Bearbeitungszwecke und für mögliche Anschlussfragen gespeichert. Diese Daten werden ohne Ihre spezifische Zustimmung nicht weitergegeben.
            
            <br>Änderung der Datenschutzbestimmungen
            
            <br>Unsere Datenschutzerklärung können in unregelmäßigen Abständen angepasst werden, damit sie den aktuellen rechtlichen Anforderungen entsprechen oder um Änderungen unserer Dienstleistungen umzusetzen, z. B. bei der Einfügung neuer Angebote. Für Ihren nächsten Besuch gilt dann automatisch die neue Datenschutzerklärung.
            
            <br><br>Kontakt zum Datenschutzmitarbeiter
            
            <br>Für Fragen zum Datenschutz schicken Sie uns bitte eine Nachricht an ferienhaus-reinharz@t-online.de mit dem Betreff „Datenschutz“.
            
            <br>Diese Widerrufsbelehrung Seite wurde bei datenschutzerklärungmuster.de erstellt.</p>
      </div>
      <div class="col-md-2"/>
    </div>
</template>

<script>
export default {
  name: "ImpressumView",
  data(){
    return{

    };
  },
  components: {
    
  },
  methods: {

  },
  created(){

  }
}
</script>

<style lang="scss">

</style>