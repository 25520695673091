<template>
    <br>
    <div class="row">
        <div class="col-md-1"/>
        <div class="col-md-11">
            <h3>Oder:</h3>
        </div>
    </div>
    <div>
        <address class="text-center" id="Kontakt">
            <p class="h5">Rolf Quandt</p>
            <p class="h5">Reinharz 64</p>
            <p class="h5">06905 Bad Schmiedeberg OT Reinharz</p>
            <p class="h5">Tel.: 
                <a href="tel:+493492570422">034925/70422</a>
            </p>
            <p class="h5">
                Email:
                <a href="mailto:ferienhaus-reinharz@t-online.de">ferienhaus-reinharz@t-online.de</a>
            </p>
        </address>
    </div>
</template>

<style lang="scss">
    p{
        margin: 0px;
    }
</style>