<template>
    <div class="row">
        <div class="col-1"/>
        <div class="col-10">
            <p class="text-center h5"><b>
            Gemütliches, freistehendes Ferienhaus (ca. 70qm) mit großem Garten, ruhige Ortslage, kein
            Durchgangsverkehr.</b>
            </p>
            <p class="text-center h5">
            Ideal für Familien mit Kindern mitten in der Dübener Heide.
            </p>
            <p class="text-center h5">
            2 Schlafzimmer, Du/WC, Küche, Wohn- und Esszimmer<br>
            Radio und SAT-TV, <br>
            Terasse mit Gartenmöbel und Grill,
            </p>
            <p class="text-center h5">
            Schaukel und Sandkasten für unsere kleinen Bewohner. <br>
            Autostellplatz direkt am Haus. <br>
            Abschließbarer Fahrradschuppen inklusive Fahrräder.
            </p>
        </div>
        <div class="col-1"/>
    </div>
</template>

<style lang="scss">
    p{
        margin-top: 20px !important;
    }
</style>